<template>
  <!-- <v-chart class="chart" :option="option" /> -->

  <!-- <h3>{{ time }}</h3> -->
  <div class="bg-white shadow rounded py-6 mt-6">
    <div class="flex divide-x md:divide-x-0 md:flex-col md:divide-y">
      <div class="w-2/5 md:w-full px-6 flex flex-col">
        <div class="text-center text-lg mb-4">答題分析</div>
        <div class="flex relative">
          <div class="h-0 pb-[50%] w-1/2 relative">
            <v-chart
              class="chart absolute inset-0 pointer-events-none"
              :option="option"
            />
          </div>
          <div
            class="w-1/2 flex flex-col justify-center pl-6 text-sm text-gray"
          >
            <div
              v-for="(res, index) in resultCnt"
              :key="index"
              class="mb-2 flex items-center"
            >
              <div
                v-if="index !== 0"
                class="w-6 h-3 mr-2 rounded-sm"
                :style="{ backgroundColor: chatColor[index] }"
              ></div>
              {{ res.name }} {{ res.cnt }}
            </div>
            <div class="text-xs mt-3">*不計分:{{ nonCalculateQtype }}</div>
          </div>
        </div>
      </div>

      <div class="w-2/5 md:w-full px-6 flex flex-col md:py-8">
        <div class="text-center text-lg">時間掌控</div>
        <div class="flex text-gray justify-around items-center flex-grow">
          <div class="flex flex-col items-center space-y-3">
            <div class=" ">作答時間</div>
            <div class="text-primary text-4xl">
              {{ formatSec(time.ansTime) }}
            </div>
          </div>
          <div class="flex flex-col items-center space-y-3">
            <div class=" ">平均單題作答時間</div>
            <div class="text-yellow text-4xl">
              {{ formatSec(time.avgTime) }}
            </div>
          </div>
        </div>
      </div>

      <div class="flex items-center w-1/5 md:w-full justify-center px-6 pt-7">
        <BtnRetest
          :type="type"
          :size="'large'"
          :test-id="$route.params.testId"
          :need-confirm="true"
        >
          重新測驗
        </BtnRetest>
      </div>
    </div>
  </div>
</template>

<script>
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { PieChart } from 'echarts/charts';
import { formatSec, getScoreColor } from '@/utils';

import BtnRetest from '@/modules/tester/BtnRetest.vue';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components';
import VChart, { THEME_KEY } from 'vue-echarts';
import { ref, defineComponent, inject, computed } from 'vue';

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default defineComponent({
  name: 'Statistics',
  components: {
    VChart,
    BtnRetest,
  },
  setup: (props) => {
    const testerData = inject('testerData');

    const time = inject('time');

    const pieData = computed(() => {
      return [
        { value: testerData.resultCnt[1].cnt, name: '正確題數' },
        { value: testerData.resultCnt[2].cnt, name: '錯誤題數' },
        { value: testerData.resultCnt[3].cnt, name: '未作答題數' },
      ];
    });

    const option = ref({
      title: {
        text: testerData.scoreRatio.value,
        subtext: '%',
        left: 'center',
        top: 'middle',
        itemGap: -15,
        textStyle: {
          color: '#78c2a7',
          fontSize: 48,
          fontWeight: 'normal',
        },
        subtextStyle: {
          fontSize: 16,
        },
      },
      series: [
        {
          name: '答題分析',
          type: 'pie',
          radius: ['155%', '175%'],
          avoidLabelOverlap: true,
          left: 'center',
          label: {
            show: false,
          },
          itemStyle: {
            borderRadius: 4,
            borderColor: '#fff',
            borderWidth: 2,
          },
          data: pieData.value,
        },
      ],
      color: ['#7abecf', '#f36e65', '#dddddd'],
    });

    const chatColor = ref([
      '#fff',
      getScoreColor(60),
      getScoreColor(10),
      `#dddddd`,
    ]);
    return {
      option,
      time,
      resultCnt: testerData.resultCnt,
      formatSec,
      chatColor,
      nonCalculateQtype: testerData.nonCalculateQtype,
      type: testerData.type,
    };
  },
});
</script>
