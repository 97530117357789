<template>
  <div v-loading.body="loading" class="btn" @click="toggle">
    <font-awesome-icon :icon="['fas', 'chevron-left']" />
    <span class="mt-1">即<br />時<br />分<br />析</span>
    <img src="@/assets/imgs/analysisRole1.png" alt="" class="img" />
  </div>

  <ElDrawer
    v-model="drawer"
    :with-header="false"
    :size="isMobile ? '80%' : 450"
  >
    <div class="divide-y flex flex-col">
      <!-- 即時統計分析 -->
      <div class="section flex items-center space-x-4 justify-center">
        <img src="@/assets/imgs/analysisRole2.png" alt="" />
        <div class="flex flex-col">
          <div class="text-2xl">即時統計分析</div>
          <el-button
            type="info"
            size="default"
            class="mt-2"
            @click="$router.push({ name: 'Analyze' })"
            >前往完整統計分析</el-button
          >
          <div class="ml-3 mt-2">
            <div class="flex items-center">
              <span class="text-xs mr-2"
                >最後測驗更新時間 : {{ data.updTime
                }}<br />數據持續更新中，請耐心等待</span
              >

              <el-button type="primary" @click="fetcher">
                <font-awesome-icon :icon="['fa', 'redo-alt']" />
              </el-button>
            </div>
          </div>
        </div>
      </div>

      <!-- 試卷表現 -->
      <div v-if="data.exam" class="section">
        <div class="text-center text-lg">試卷表現</div>
        <div class="mt-6 flex justify-around">
          <div class="flex flex-col items-center">
            <div class="text-gray">已作答試卷</div>
            <div class="text-5xl text-primary pl-6">
              {{ data.exam.cnt }}
              <sub class="text-sm relative bottom-0 -left-1">份</sub>
            </div>
          </div>

          <div class="flex flex-col items-center">
            <div class="text-gray">平均正確率</div>
            <div
              class="text-5xl text-primary pl-6"
              :style="{
                color: getScoreColor(data.exam.avgCorrectRate),
              }"
            >
              {{ data.exam.avgCorrectRate }}
              <sub class="text-sm relative bottom-0 -left-1">%</sub>
            </div>
            <!-- <div class="text-xs mt-2 text-gray">目標 90 %</div> -->
          </div>
        </div>
      </div>

      <!-- 整體試題表現 -->
      <!-- <div class="section">
        <div class="text-center text-lg">整體試題表現</div>
        <div
          v-if="data.question?.completeRate"
          class="flex justify-around mt-6 md:flex-col"
        >
          <div class="flex items-center flex-col">
            <ElProgress
              type="circle"
              :percentage="data.question.completeRate"
              :stroke-width="10"
              :color="'var(--el-color-primary)'"
              :width="160"
            >
              <template #default="{ percentage }">
                <div class="text-sm text-gray">完成率</div>
                <div class="text-primary mt-1">
                  <span class="text-5xl">{{ percentage }}</span>
                  <span class="text-base -mt-2">%</span>
                </div>
              </template>
            </ElProgress>
            <div class="flex justify-center text-sm mt-1">
              <div>
                <div>目標 {{ data.question.targetCnt }}</div>
                <div>已答 {{ data.question.completeCnt }}</div>
                <div>再寫 {{ data.question.inCompleteCnt }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <p class="my-4 text-center">
            此區統計數據需先前往
            <a
              href="#"
              class="underline"
              @click.prevent="$router.push({ name: 'Assistant.target' })"
              >設定個人目標</a
            >
          </p>
        </div>
      </div> -->

      <!-- 目標期間表現 -->
      <div class="section">
        <div class="text-center text-lg">目標期間表現</div>
        <div v-if="data.rows?.length" class="mt-6 space-y-6">
          <div v-for="row in data.rows" :key="row.name">
            <div class="text-lg text-gray pl-1">{{ row.name }}</div>
            <div class="mt-2 space-y-2">
              <el-progress
                :text-inside="true"
                :stroke-width="26"
                :percentage="
                  Math.min((row.completeCnt / row.targetCnt) * 100, 100)
                "
                :color="'var(--el-color-info)'"
              >
                <span
                  >已完成 {{ row.completeCnt }} / {{ row.targetCnt }} 題</span
                ></el-progress
              >

              <el-progress
                :text-inside="true"
                :stroke-width="26"
                :percentage="
                  Math.min((row.correctRate / row.targetCorrectRate) * 100, 100)
                "
                :color="getProgressColors()"
              >
                <span>
                  正確率 {{ row.correctRate }}% / {{ row.targetCorrectRate }}%
                </span></el-progress
              >
            </div>
          </div>
        </div>

        <div v-else>
          <p class="my-4 text-center">
            此區統計數據需先前往
            <a
              href="#"
              class="underline"
              @click.prevent="$router.push({ name: 'Assistant.target' })"
              >設定個人目標</a
            >
          </p>
        </div>
      </div>
    </div>
  </ElDrawer>
</template>

<script>
import { ElMessage, ElDrawer, ElProgress } from 'element-plus';
import { getScoreColor, getProgressColors } from '@/utils/index.js';
import Analysis from '@/api/Analysis.js';
import { ref, inject } from 'vue';
export default {
  components: { ElDrawer, ElProgress },
  setup() {
    const isMobile = inject('isMobile');
    const drawer = ref(false);
    const data = ref(null);
    const loading = ref(false);

    const fetcher = () => {
      loading.value = true;
      return Analysis.intro()
        .then((res) => {
          if (res.code === 1) {
            data.value = res.data;
          }
          return res;
        })
        .finally((e) => {
          loading.value = false;
        });
    };

    // const limitNum(_num,limit=100)=>{
    //   return Math.min(_)
    // }
    const toggle = async () => {
      if (!data.value) {
        const res = await fetcher();
        if (res.code === 1) {
          drawer.value = !drawer.value;
        } else {
          ElMessage.error('資料載入失敗');
        }
      } else {
        drawer.value = !drawer.value;
      }
    };

    return {
      loading,
      drawer,
      getScoreColor,
      getProgressColors,
      isMobile,
      data,
      toggle,
      fetcher,
    };
  },
};
</script>

<style lang="sass" scoped>
.btn
    @apply bg-info fixed right-0 text-white flex justify-center items-center flex-col text-xl pt-6 px-6 rounded-l-lg shadow duration-300 hover:pr-8 cursor-pointer z-10
    top: 5rem
    padding-bottom: 150px
    @screen md
      top: 7rem
      padding-bottom: 85px
      @apply px-3 pt-4

    .img
        position: absolute
        bottom: 8px
        right: 4px
        width: 100px
        max-width: none
        @screen md
          width: 50px


.section
    @apply py-8
</style>
