<template>
  <div>
    <el-button
      type="primary"
      :disabled="disabled"
      :size="size"
      @click="preSubmit"
    >
      <slot>
        <font-awesome-icon :icon="['fas', 'pen']" class="mr-1" />
        開始測驗
      </slot>
    </el-button>
    <Replace-Confirm :test-id="recordTestId" @submit="submit"></Replace-Confirm>
  </div>
</template>

<script>
import { ref, toRefs, provide, inject } from 'vue';
import useDialog from '@/hook/useDialog';
import ReplaceConfirm from './_ReplaceConfirm.vue';
import Tester from '@/api/Tester';
import { useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';
import useConfirmMsg from './useConfirmMsg.js';
export default {
  components: { ReplaceConfirm },
  props: {
    size: {
      type: String,
      default: 'default',
    },
    type: {
      type: String,
      default: null,
    },

    testId: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },

    needConfirm: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const router = useRouter();

    const { type, testId, needConfirm } = toRefs(props);

    const auth = inject('auth');
    const dialog = useDialog();
    provide('dialog', dialog);

    const recordTestId = ref(null);

    const preSubmit = async () => {
      if (!auth.guard()) return;
      const res = await Tester.check();
      if (res.code === 1) {
        dialog.toggle({ show: true });
        recordTestId.value = res.data.testId;
      } else {
        submit();
      }
    };

    const permission = inject('permission');
    const submit = async () => {
      try {
        let confirm = true;

        if (confirm && needConfirm.value) {
          confirm = await useConfirmMsg();
        }

        if (confirm) {
          Tester.add({
            type: type.value,
            testId: testId.value,
          }).then(async (res) => {
            if (!(await permission.dialog(res.status == 402 ? '0' : '1')))
              return;
            if (res.code === 1) {
              router.push({
                name: 'Tester',
                params: { testId: res.data.testId },
              });
            } else {
              ElMessage({ message: res.message, type: 'error' });
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    return { submit, preSubmit, recordTestId };
  },
};
</script>
