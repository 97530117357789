<template>
  <div>
    <transition name="fade">
      <div
        v-if="loading"
        class="fixed z-30 inset-0 bg-white bg-opacity-75 flex justify-center items-center flex-col"
      >
        <img src="@/assets/imgs/analysisRole4.png" alt="" class="w-64" />
        <div class="text-primary text-xl mt-4">
          閱卷中，請稍候!
          <font-awesome-icon
            :icon="['fas', 'spinner']"
            class="animate-spin ml-2"
          />
        </div>
      </div>
    </transition>
    <div class="">
      <AnalysisDrawer></AnalysisDrawer>

      <control v-if="rowsData"></control>
      <div class="container">
        <Statistics v-if="resultCnt.length" />
      </div>
      <List v-slot="slot" class="main">
        <Quest
          :id="'quest-' + slot.row.no"
          :row="slot.row"
          @update:conf="slot.updateConf"
          @update:memo="slot.updateMemo"
          @update:saved="slot.updateSaved"
        />
      </List>
    </div>
  </div>
</template>
<script>
import { provide, onMounted } from 'vue';

import useResult from '@/modules/tester/useResult';
import Statistics from '@/modules/tester/statistics/Index.vue';
import Quest from '@/modules/quest/Result.vue';
import AnalysisDrawer from '@/modules/tester/analysis/Index.vue';
import Control from '@/modules/tester/control/Simple.vue';

import List from '@/modules/tester/List';
export default {
  components: { Statistics, Quest, Control, List, AnalysisDrawer },
  setup() {
    const testerData = useResult();
    const { saveds, updateSaved, updateConf } = testerData;
    provide('actions', { saveds, updateSaved, updateConf });
    onMounted(() => {
      testerData.fetcher({ empty: true });
    });

    provide('testerData', testerData);

    provide('time', testerData.time);

    return {
      rowsData: testerData.rowsData,
      resultCnt: testerData.resultCnt,
      loading: testerData.loading,
    };
  },
};
</script>
