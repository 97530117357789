import { reactive, ref } from 'vue';

import Tester from '@/api/Tester';
import Exam from '@/api/Exam';
import Question from '@/api/Question';
import useRows from './useRows';
import { ElMessage } from 'element-plus';
import { useRoute } from 'vue-router';
import useAnswer from './useAnswer';
import useSaved from '@/modules/saved/useSaved';
export default () => {
  const route = useRoute();
  const testId = ref(route.params.testId);

  const {
    form,
    rowsData,
    dataType,
    fetcher: _fetcher,
    updateMode,
    updateConf,
    saveds,
    updateSaved,
    more,
    next,
    prev,
    goto,
    loading,
  } = useRows({
    API: Tester.result,
    testId: testId.value,
    defaultFrom: {},
  });

  const answerData = useAnswer({
    type: 'testResult',
    testId: testId.value,
  });

  const resultCnt = reactive([]);

  const nonCalculateQtype = ref('');
  const scoreRatio = ref(0);

  const time = reactive({});
  const type = ref('');
  const fetcher = () => {
    _fetcher().then((res) => {
      if (res.code === 1) {
        resultCnt.splice(0, resultCnt.length, ...res.data.resultCnt);
        Object.assign(time, res.data.time);
        rowsData.total = res.data.all;
        rowsData.answered = rowsData.rows.filter(
          (x) => x.userResult?.length,
        ).length;
        nonCalculateQtype.value = res.data.nonCalculateQtype;
        scoreRatio.value = res.data.score;
        type.value = res.data.type;
      }
    });
    answerData.fetcher();
  };
  const updateMemo = ({ id, memo }) => {
    const idx = rowsData.rows.findIndex((x) => {
      return x.id === id;
    });

    if (rowsData.rows[idx].memo == memo) return;

    rowsData.rows[idx].memo = memo;
  };

  const addSelf = (data) => {
    return Exam.addSelf({
      ...data,
      testId: testId.value,
      // ids: rowsData.rows.map((x) => x.id),
    }).then((res) => {
      if (res.code === 1) {
        ElMessage('成功建立');
      } else {
        ElMessage.error('失敗');
      }
      return res;
    });
  };

  return {
    updateMemo,
    updateConf,
    saveds,
    updateSaved,
    form,
    resultCnt,
    time,
    fetcher,
    rowsData,
    updateMode,
    next,
    prev,
    goto,
    loading,
    addSelf,
    nonCalculateQtype,
    nonCalculates: answerData.nonCalculates,
    scoreRatio,
    answerResult: answerData.form,
    answered: answerData.answered,
    type,
    testId,
  };
};
