<template>
  <El-Dialog v-model="dialog.state.show" destroy-on-close width="460px">
    <div class="text-center">
      <font-awesome-icon
        :icon="['fas', 'exclamation-circle']"
        class="mr-1 text-yellow-400 text-[6rem] mb-8 mt-0"
      />
      <div class="text-2xl font-bold mt-2">
        你尚有未完成的測驗，是否確定要覆蓋上一次測驗?
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="text" @click="dialog.state.show = false"
          >取消</el-button
        >
        <el-button @click="toTester">繼續上次測驗</el-button>
        <el-button type="primary" @click="submit">確認覆蓋</el-button>
      </span>
    </template>
  </El-Dialog>
</template>

<script>
import { inject, toRefs } from 'vue';

import { useRouter } from 'vue-router';
import { ElDialog } from 'element-plus';

export default {
  components: { ElDialog },
  props: {
    testId: {
      type: [String, Number],
      default: null,
    },
  },
  emits: ['submit'],

  setup(props, { emit }) {
    const dialog = inject('dialog');
    const { testId } = toRefs(props);
    const router = useRouter();

    const auth = inject('auth');

    const toTester = () => {
      dialog.toggle({ show: false });

      if (!auth.guard()) return;

      router.push({
        name: 'Tester',
        params: { testId: testId.value },
      });
    };
    const submit = () => {
      emit('submit');
    };
    return { dialog, toTester, submit };
  },
};
</script>
