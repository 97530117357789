<template>
  <div
    ref="draggable"
    class="bg-white border rounded-lg shadow flex items-center space-x-4 p-4 z-30 fixed hover:shadow-2xl transition-shadow"
    :style="{
      userSelect: 'none',
      position: 'fixed',
      top: `${restrictedY}px`,
      left: `${restrictedX}px`,
    }"
  >
    <div>
      <font-awesome-icon
        :icon="['fas', 'grip-lines-vertical']"
        class="text-gray opacity-40 text-2xl cursor-move"
      />
    </div>

    <Summery :status="true" :is-result="true" />
    <Mode />
  </div>
</template>

<script>
import Summery from './_Summery.vue';
import Mode from './_Mode.vue';
import useDrag from './useDrag';

export default {
  components: { Summery, Mode },
  setup() {
    const { restrictedY, restrictedX, draggable } = useDrag();

    return {
      draggable,
      restrictedX,
      restrictedY,
    };
  },
};
</script>
